<template>
  <div>
    <h1>自然光照治疗系统</h1>
    <div id="palnBox">
      <div class="textBox"><div>光照诊疗方案管理</div></div>
      <div id="formBox">
        <el-form :model="list" ref="list" class="form1">
          <el-form-item>
            <el-input
              class="input1"
              placeholder="方案名称"
              autocomplete="off"
              v-model="list.appName"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              class="input1"
              placeholder="时间(分钟）"
              autocomplete="off"
              v-model="list.data[0]"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              class="input1"
              placeholder="强度(0-10000）"
              autocomplete="off"
              maxlength="5"
              oninput="if(value>10000)value=10000"
              v-model="list.data[1]"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-badge value="该模块默认为A0">
              <el-input
                disabled
                class="input1"
                autocomplete="off"
                v-model="list.appId"
              ></el-input>
            </el-badge>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="adds" class="addBtn"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
        <!-- 方案选择下拉框 -->
        <div id="form2">
          <template>
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in VrCare"
                :key="item.id"
                :label="item.appName"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-button type="danger" @click="delOne(value)">删除</el-button>
            <el-button @click="getPatientOne(value)">查看</el-button>
          </template>
        </div>
        <!-- 方案详情 卡片组件 -->
        <el-card class="box-card">
          <h1>方案详情</h1>
          <div class="text item">方案名称: {{ lookOne.appName }}</div>
          <div class="text item">
            光照强度:
            {{ strengthShow }}
          </div>
          <div class="text item">光照时长：{{ tiemShow }}</div>
          <div class="text item">应用编号: {{ lookOne.appId }}</div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../http/axios";
import { Message } from "element-ui";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("VRPlan");
import api from "../http/api";
export default {
  data() {
    return {
      dialogVisible: false,
      input: "",
      value: "",
      lookOne: {
        name: "",
        time: "",
        modular: "",
        type: "",
      },
      list: {
        appId: "A0",
        appName: "",
        data: [],
        tname: "A0",
        type: "A0",
      },
      id: "",
      form: [],
      time: 0,
      strength: 0,
    };
  },
  mounted() {},
  computed: {
    ...mapState(["data", "modularData"]),
    datajson() {
      return [this.time, this.strength];
    },
    tiemShow() {
      if (this.lookOne.data) {
        let data = this.lookOne.data;
        let arr = data.split(",");
        let a = arr.map(Number)[0];
        return a;
      }
    },
    strengthShow() {
      if (this.lookOne.data) {
        let data = this.lookOne.data;
        let arr = data.split(",");
        let a = arr.map(Number)[1];
        return a;
      }
    },
    // 筛选Vr治疗模块的数据
    VrCare() {
      return this.data.filter((item) => item.appId == "A0");
    },
  },
  created() {
    this.getAllVRPlan();
  },

  methods: {
    ...mapActions(["getAllVRPlan", "addVRPlan", "delVRPlan", "updateCal"]),

    //   点击修改的弹出框方法
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    // 新增按钮
    async adds() {
      // const data = await api.VRPlan.addVRPlan(this.list);
      const data = await axios({
        url: `/CareType/add?appid=${this.list.appId}&appname=${this.list.appName}&type=${this.list.type}&tname=${this.list.tname}&data=${this.list.data}`,
        method: "POST",
        data,
      });
      if (data.data.msg == "请求处理正常") {
        Message.success("新增成功");
        this.getAllVRPlan();
        // console.log(22222222, data);
      } else {
        Message.warning("新增失败，请重新输入");
      }
    },
    // 删除
    delOne(id) {
      // console.log("id", id);
      // 删除提示框
      this.$confirm("此操作将永久删除该数据，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "danger",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功！",
          });
          this.delVRPlan(id);
        })

        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 获取某一个患者数据
    async getPatientOne(_id) {
      const { data } = await axios({
        url: `/CareType/?_id=${_id}`,
        method: "get",
        params: {
          _id,
        },
      });
      if (data.status == 0) {
        this.lookOne = data.data;
        // console.log("获取id", this.lookOne.data.split(",")[0]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text {
  font-size: 14px;
}

.box-card {
  width: 480px;
  div {
    margin: 20px;
  }
  h1 {
    width: 100%;
    text-align: center;
  }
}
#palnBox {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  align-items: center;
  margin: 40px;
}
.box-card {
  width: 590px;
  border-radius: 20px;
  margin: 10px;
  margin-left: 40px;
}
#formBox {
  height: 100%;
}
.textBox {
  width: 200px;
  text-align: center;
  height: 500px;
  display: flex;
  align-items: center;
  div {
    border-radius: 50px;
    width: 200px;
    line-height: 70px;
    height: 70px;
    background: cornflowerblue;
  }
}
.form1,
#form2 {
  display: flex;
  flex-wrap: wrap;
  width: 650px;
  margin: 10px;
  margin-left: 40px;
  .el-form-item {
    height: 50px;

    display: flex;
    -ms-flex-item-align: center;
    margin: 5px;
    .el-input {
      width: 125px;
      margin-top: 10px;
    }
    .input1,
    .input2 {
      width: 170px;
    }
    // .el-badge__content.is-fixed {
    //   position: absolute;
    //   top: -3px;
    //   right: 150px;
    //   transform: translateY(-50%) translateX(100%);
    // }
  }
  .el-select {
    width: 421px;
  }
}
.addBtn {
  margin-top: 10px;
}
</style>
